import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.png";
import imaginify from "../../assets/imaginify.png";

const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Discord Bot",
    category: "bot",
    href: "https://discord.gg/5eHxRjS6FN",
  },
  {
    id: 2,
    image: Work2,
    title: "Stat Bot",
    category: "bot",
  },
  {
    id: 3,
    image: imaginify,
    title: "Imaginify",
    category: "web",
    href: "https://imaginify-tma.vercel.app/",
  },
];

const projectsNav = [
  {
    name: "all",
  },
  {
    name: "bot",
  },
  {
    name: "web",
  },
  {
    name: "visual",
  },
];

export { projectsData, projectsNav };
