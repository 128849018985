import React, { useState } from "react";
import "./services.css";

const Services = () => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I offer</span>

      <div className="services__container container grid">
        <div className="services__content">
          <div>
            <i className="uil uil-web-grid  services__icon"></i>
            <h3 className="services__title">
              Digital <br /> Marketing
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(1)}>
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 1
                ? "services__modal services__active"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>
              <h3 className="services__modal-title">Digital Marketing</h3>
              <p className="services__modal-description">
                Boost your business with my tailored digital marketing services.
                I enhance online presence, engage audiences, and drive results.
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>SEO Optimization:</strong> Improve your website’s
                    visibility on search engines.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Social Media Marketing:</strong> Engage and grow
                    your audience across major social media platforms.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Content Marketing:</strong> Develop and distribute
                    valuable, relevant content to attract your audience.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Email Marketing:</strong> Create personalized email
                    campaigns that drive conversions with targeted messaging.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-arrow  services__icon"></i>
            <h3 className="services__title">
              Web <br /> Developement
            </h3>
          </div>

          <span onClick={() => toggleTab(2)} className="services__button">
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 2
                ? "services__modal services__active"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>
              <h3 className="services__modal-title">Web Developement</h3>
              <p className="services__modal-description">
                Elevate your digital presence with my web development service. I
                create custom, responsive, and user-friendly websites to meet
                your business needs.
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Custom Website Design:</strong> Tailored websites
                    that reflect your brand identity.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Responsive Design:</strong> Ensure your website
                    looks and functions perfectly on all devices.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>E-Commerce Solutions:</strong> Build robust online
                    stores with seamless shopping experiences.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Web Applications:</strong> Develop powerful web
                    applications that enhance user engagement.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Maintenance and Support:</strong> I provide ongoing
                    maintenance, updates, and support.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="services__content">
          <div>
            <i className="uil uil-edit services__icon"></i>
            <h3 className="services__title">
              Graphics <br /> Design
            </h3>
          </div>

          <span onClick={() => toggleTab(3)} className="services__button">
            View More
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>

          <div
            className={
              toggleState === 3
                ? "services__modal services__active"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>

              <h3 className="services__modal-title">Graphics Design</h3>
              <p className="services__modal-description">
                Elevate your brand's visual identity with my graphic design
                service. I specialize in creating visuals that resonate and
                boost your brand presence.
              </p>

              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Brand Identity:</strong> Craft visually stunning
                    logos, color palettes etc.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Print Design:</strong> Design eye-catching marketing
                    materials for both digital and print media.
                  </p>
                </li>

                <li className="services__modal-service">
                  {/* <i className="uil uil-check-circle services__modal-icon"></i> */}
                  <p className="services__modal-info">
                    <strong>Digital Design:</strong> Create engaging digital
                    assets including social media graphics, website banners,
                    email templates etc.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
