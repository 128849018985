import platinum_alerts from "../../assets/platinum_alerts.png";
import wolf_alerts from "../../assets/wold_alerts.gif";
import gorills_trade from "../../assets/gorillz_trade.png";

export const Data = [
  {
    id: 1,
    image: platinum_alerts,
    title: "Platinum Alerts",
    description:
      "I highly recommend your Discord bot service. Your expertise shines in the exceptional bots tailored for our trading community, seamlessly integrating into our server for effortless management.",
  },
  {
    id: 2,
    image: wolf_alerts,
    title: "Wolf Alerts",
    description:
      "Impressed with the Shopify site you crafted for our slide sales! It's sleek, boosted sales, and your responsiveness was stellar. Highly recommend your e-commerce expertise!",
  },
  {
    id: 3,
    image: gorills_trade,
    title: "Gorillz Trade",
    description:
      "Impressive service! Completed quickly and efficiently, surpassing expectations. Clear communication ensured seamless fulfillment of requirements. Highly recommended for custom Discord bot solutions.",
  },
];
