import './skills.css';
import Developement from './Developement';
import Designing from './Designing';

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">My Skills</h2>
        <span className="section__subtitle">My Technical Qualifications</span>

        <div className="skills__container container grid">
            <Developement />
            <Designing />
        </div>
    </section>
  )
}

export default Skills