import React from "react";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://github.com/tasinmalif/"
        rel="noreferrer"
        target="_blank"
        className="home__social-icon"
      >
        <i className="uil uil-github-alt"></i>
      </a>
      <a
        href="https://x.com/tasinmalif/"
        rel="noreferrer"
        target="_blank"
        className="home__social-icon"
      >
        <i className="uil uil-twitter-alt"></i>
      </a>
      <a
        href="https://behance.net/tasinmalif"
        rel="noreferrer"
        target="_blank"
        className="home__social-icon"
      >
        <i className="uil uil-behance"></i>
      </a>
    </div>
  );
};

export default Social;
