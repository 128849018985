import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">TASIN M ALIF</h1>

        <ul className="footer__list">
          <li>
            <a href="#home" className="footer__link">
              Home
            </a>
          </li>

          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>

          <li>
            <a href="#skills" className="footer__link">
              Skills
            </a>
          </li>

          <li>
            <a href="#services" className="footer__link">
              Services
            </a>
          </li>

          <li>
            <a href="#portfolio" className="footer__link">
              Portfolio
            </a>
          </li>

          <li>
            <a href="#contact" className="footer__link">
              Contact
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://facebook.com/tasinmaliff/"
            target="blank"
            className="footer__social-icon"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/tasinmalif/"
            target="blank"
            className="footer__social-icon"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href="https://www.youtube.com/@tasinmaliff"
            target="blank"
            className="footer__social-icon"
          >
            <i className="bx bxl-youtube"></i>
          </a>
          <a
            href="https://www.pinterest.com/tasinmalif/"
            target="blank"
            className="footer__social-icon"
          >
            <i className='bx bxl-pinterest'></i>
          </a>
          <a
            href="https://dribbble.com/tasinmalif/"
            target="blank"
            className="footer__social-icon"
          >
            <i className='bx bxl-dribbble'></i>
          </a>
          <a
            href="https://www.linkedin.com/in/tasinmalif/"
            target="blank"
            className="footer__social-icon"
          >
            <i className='bx bxl-linkedin-square'></i>
          </a>
        </div>

        <span className="footer__copy">
          &#169; Tasin M Alif. All right reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
